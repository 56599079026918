:root {
   --tooltip-text-color: white;
   --tooltip-background-color: rgba(30, 30, 30, 0.75);
   --tooltip-margin: 30px;
   --tooltip-arrow-size: 6px;
 }
 
 .Tooltip-Wrapper {
   position: relative; 
 }

 .Tooltip-Tip {
   position: absolute;
   border-radius: 16px;
   left: 50%;
   transform: translateX(-50%);
   padding-top: 8px;
   padding-bottom: 8px;
   padding-left: 16px;
   padding-right: 16px;
   color: var(--tooltip-text-color);
   background: var(--tooltip-background-color);
   font-size: 14px;
   line-height: 20px;
   z-index: 10000;
   white-space: nowrap;
 }
 
//  .Tooltip-Tip::before {
//    content: " ";
//    left: 50%;
//    border: solid transparent;
//    height: 0;
//    width: 0;
//    position: absolute;
//    pointer-events: none;
//    border-width: var(--tooltip-arrow-size);
//    margin-left: calc(var(--tooltip-arrow-size) * -1);
//  }
 
 .Tooltip-Tip.top {
   top: -41px
 }

 .Tooltip-Tip.top::before {
   top: 100%;
   border-top-color: var(--tooltip-background-color);
 }
 

 .Tooltip-Tip.right {
  left: calc(75% + var(--tooltip-margin));
   top: 50%;
   transform: translateX(0) translateY(-50%);
   
 }

 .Tooltip-Tip.right::before {
   left: calc(var(--tooltip-arrow-size) * -1);
   top: 50%;
   transform: translateX(0) translateY(-50%);
   border-right-color: var(--tooltip-background-color);
   
 }
 
 
 .Tooltip-Tip.bottomAdmin {
   bottom: calc(var(--tooltip-margin) * -1.5);
   left: -20px;
 }
 .Tooltip-Tip.bottom {
  bottom: calc(var(--tooltip-margin) * -1.5);
}
    

 .Tooltip-Tip.bottom::before {
   bottom: 100%;
   border-bottom-color: var(--tooltip-background-color);
 }
 

 .Tooltip-Tip.left {
   left: auto;
   right: calc(100% + var(--tooltip-margin));
   top: 50%;
   transform: translateX(0) translateY(-50%);
 }
 .Tooltip-Tip.leftAdmin {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

 .Tooltip-Tip.left::before {
   left: auto;
   right: calc(var(--tooltip-arrow-size) * -2);
   top: 50%;
   transform: translateX(0) translateY(-50%);
   border-left-color: var(--tooltip-background-color);
 }

 
 table tbody tr:last-child td:nth-child(2) {
  .Tooltip-Tip.bottom {
  left: 0px;
  bottom: 12px;
  transform: translateX(0) translateY(-50%);
  }
 }
 
 

 